import React, { useState } from "react";
import { SurveyQuestionType, SurveyTemplate } from "app-types/template";
import Heading from "components/core/Typography/Heading";
import SafeLink from "components/core/SafeLink";
import { getSurveyTemplatePagePath } from "utils/path";
import { useTranslation } from "react-i18next";
import {
  StyledTemplatePreview,
  TemplateBackground,
  TemplateDescription,
  TemplateFooter,
  TemplateFooterLeft,
  TemplateFooterRight,
} from "components/ui/base/TemplatePreview/index.style";
import Fade from "components/core/Animation/extended/Fade";
import MessengerPreview from "components/ui/extended/MessengerPreview";
import ButtonQuestion from "components/ui/extended/MessengerPreview/Survey/Question/ButtonQuestion";
import TextareaQuestion from "components/ui/extended/MessengerPreview/Survey/Question/TextareaQuestion";
import TextboxQuestion from "components/ui/extended/MessengerPreview/Survey/Question/TextboxQuestion";
import StarQuestion from "components/ui/extended/MessengerPreview/Survey/Question/StarQuestion";
import {
  Dot,
  DotsContainer,
  QuestionsContainer,
} from "templates/templates/survey/index.style";
import Button from "components/core/Button";
import { theme } from "styles/theme";

interface SurveyPreviewProps {
  survey: SurveyTemplate;
}

const SurveyPreview = (props: SurveyPreviewProps) => {
  const { t } = useTranslation();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [paused, setPaused] = useState<boolean>(false);

  setTimeout(() => {
    if (!paused) {
      const nextActiveQuestion =
        (activeQuestion + 1) % props.survey.questions.length;
      setActiveQuestion(nextActiveQuestion !== 0 ? nextActiveQuestion : 0);
    }
  }, 5000);

  return (
    <StyledTemplatePreview>
      <QuestionsContainer>
        {props.survey.questions.map((question, index) => {
          switch (question.type) {
            case SurveyQuestionType.Button:
              return (
                <TemplateBackground
                  show={activeQuestion === index}
                  key={`survey-question-${index}`}
                >
                  <Fade direction={"up"} delay={100}>
                    <MessengerPreview
                      withBubble
                      direction={"left"}
                      accentColor={theme.accentColor}
                    >
                      <ButtonQuestion
                        title={question.title}
                        choices={question.choices}
                        accentColor={theme.accentColor}
                      />
                    </MessengerPreview>
                  </Fade>
                </TemplateBackground>
              );
            case SurveyQuestionType.Textarea:
              return (
                <TemplateBackground
                  show={activeQuestion === index}
                  key={`survey-question-${index}`}
                >
                  <Fade direction={"up"} delay={100}>
                    <MessengerPreview
                      withBubble
                      direction={"left"}
                      accentColor={theme.accentColor}
                    >
                      <TextareaQuestion
                        title={question.title}
                        description={question.subtitle}
                        accentColor={theme.accentColor}
                      />
                    </MessengerPreview>
                  </Fade>
                </TemplateBackground>
              );
            case SurveyQuestionType.Textbox:
              return (
                <TemplateBackground
                  show={activeQuestion === index}
                  key={`survey-question-${index}`}
                >
                  <Fade direction={"up"} delay={100}>
                    <MessengerPreview
                      withBubble
                      direction={"left"}
                      accentColor={theme.accentColor}
                    >
                      <TextboxQuestion
                        title={question.title}
                        description={question.subtitle}
                        accentColor={theme.accentColor}
                      />
                    </MessengerPreview>
                  </Fade>
                </TemplateBackground>
              );
            case SurveyQuestionType.Star:
              return (
                <TemplateBackground
                  show={activeQuestion === index}
                  key={`survey-question-${index}`}
                >
                  <Fade direction={"up"} delay={100}>
                    <MessengerPreview
                      withBubble
                      direction={"left"}
                      accentColor={theme.accentColor}
                    >
                      <StarQuestion
                        title={question.title}
                        description={question.subtitle}
                        accentColor={theme.accentColor}
                      />
                    </MessengerPreview>
                  </Fade>
                </TemplateBackground>
              );
          }
        })}
        <DotsContainer>
          {props.survey.questions.length > 1 &&
            props.survey.questions.map((question, index) => {
              return (
                <Dot
                  onClick={() => {
                    setPaused(true);
                    setActiveQuestion(index);
                  }}
                  active={activeQuestion === index}
                  key={`survey-question-${index}`}
                />
              );
            })}
        </DotsContainer>
      </QuestionsContainer>

      <TemplateFooter>
        <TemplateFooterLeft>
          <Heading level={4}>{props.survey.name}</Heading>
          <TemplateDescription>{props.survey.description}</TemplateDescription>
        </TemplateFooterLeft>

        <TemplateFooterRight>
          <Button
            as={SafeLink}
            to={getSurveyTemplatePagePath(
              props.survey.survey_template_id,
              props.survey.name,
              props.survey.author.username
            )}
            icon={{ type: "arrow-right", size: 16 }}
          >
            {t("components.ui.base.TemplatePreview.goTo")}
          </Button>
        </TemplateFooterRight>
      </TemplateFooter>
    </StyledTemplatePreview>
  );
};

export default SurveyPreview;
