import styled from "styled-components";

const MessageContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 430px;
  user-select: none;
`;

const MessageContentInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.accentLighten80Color};
  color: ${(props) => props.theme.titleColor};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  word-break: break-word;
  background: ${(props) => props.theme.accentLighten80Color};
  border-radius: 8px 0 8px 8px;

  p {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  a {
    font-size: 14px !important;
    line-height: 24px !important;
  }
`;

export { MessageContainer, MessageContentInnerContainer };
