import React, { ReactElement, useContext, useState } from "react";
import { StyledFAQ, Tab, Tabs } from "./index.style";
import Heading from "components/core/Typography/Heading";
import Icon from "components/core/Icon";
import { TabHeading } from "components/ui/base/HorizontalStoriesMenu/index.style";
import { ThemeContext } from "styled-components";
import Fade from "components/core/Animation/extended/Fade";

export interface FAQProps {
  question: string;
  answer: ReactElement;
}

const FAQ = (props: FAQProps) => {
  const [activeQuestion, setActiveQuestion] = useState<boolean>(false);
  const themeContext = useContext(ThemeContext);

  return (
    <StyledFAQ>
      <Tabs>
        <Fade direction={"up"}>
          <Tab active={activeQuestion}>
            <TabHeading onClick={() => setActiveQuestion(!activeQuestion)}>
              <Icon
                type={"chevron-right"}
                size={20}
                fill={themeContext.titleColor}
              />
              <Heading level={4} weight={600}>
                {props.question}
              </Heading>
            </TabHeading>
            <Fade show={activeQuestion}>{props.answer}</Fade>
          </Tab>
        </Fade>
      </Tabs>
    </StyledFAQ>
  );
};

export default FAQ;
