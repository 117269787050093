import React from "react";
import { BLOG_PATH } from "constants/paths";
import BlogLightBoxPicture from "components/ui/base/BlogLightBoxPicture";
import { slugify } from "utils/string";
import BlogTitle from "components/ui/base/BlogTitle";
import {
  CannedResponseTemplate,
  ChatTemplate,
  EmailTemplate,
  SurveyTemplate,
} from "app-types/template";
import CannedResponsePreview from "components/ui/base/TemplatePreview/CannedResponsePreview";
import EmailPreview from "components/ui/base/TemplatePreview/EmailPreview";
import SurveyPreview from "components/ui/base/TemplatePreview/SurveyPreview";
import ChatPreview from "components/ui/base/TemplatePreview/ChatPreview";
import BlogSubscribe from "components/ui/base/BlogSubscribe";
import FAQ from "components/ui/base/FAQ";
import RegisterForBlog from "components/ui/extended/RegisterForBlog";
import BlogPostInlink from "components/ui/base/BlogPostInlink";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "templates/blog/post/index.style";
import Icon from "components/core/Icon";
import { theme } from "styles/theme";

const getObjectByType = (props: any, type: string) => {
  if (props.children) {
    for (let i = 0; i < props.children.length || 0; i++) {
      if (type === "string" && typeof props.children[i] === "string") {
        return props.children[i];
      }

      if (props.children[i].type && props.children[i].type === type) {
        return props.children[i];
      }

      if (props.children[i].props) {
        const childrenElement: any = getObjectByType(
          props.children[i].props,
          type
        );

        if (childrenElement && childrenElement.type === type) {
          return childrenElement;
        }
      }
    }

    return undefined;
  }

  return undefined;
};

const getTextInTitle = (titleElement: any): string => {
  if (titleElement.type === "text") {
    return titleElement.value;
  }

  for (let i = 0; i < titleElement.children?.length || 0; i++) {
    if (titleElement.children[i].type === "text") {
      return titleElement.children[i].value;
    }

    const child = getTextInTitle(titleElement.children[i]);
    if (child) {
      return child;
    }
  }

  throw new Error("Text in titles not found");
};

const LinkFixer = (props: any) => {
  if (props.href?.startsWith("/")) {
    const optionalTrailingSlash = !props.href.endsWith("/") ? "/" : "";

    return (
      <a {...props} href={`${BLOG_PATH}${props.href}${optionalTrailingSlash}`}>
        {props.children}
      </a>
    );
  }

  return <a {...props}>{props.children}</a>;
};

const LightBoxPictureInFigure = (props: any) => {
  if (props.className?.includes("wp-block-image")) {
    const pictureElement = getObjectByType(props, "picture");
    const imgElement = getObjectByType(props, "img");

    return (
      <BlogLightBoxPicture
        pictureProps={pictureElement?.props}
        imgProps={pictureElement ? undefined : imgElement?.props}
      />
    );
  } else {
    return <figure {...props}>{props.children}</figure>;
  }
};

const LightBoxPictureInDiv = (props: any) => {
  if (props.className?.includes("wp-block-image")) {
    const pictureElement = getObjectByType(props, "picture");
    const imgElement = getObjectByType(props, "img");

    return (
      <BlogLightBoxPicture
        pictureProps={pictureElement?.props}
        imgProps={pictureElement ? undefined : imgElement?.props}
      />
    );
  }

  return <div {...props}>{props.children}</div>;
};

const FAQElement = (props: any) => {
  const question = getObjectByType(props, "h3");
  const answer = getObjectByType(props, "span");

  return <FAQ question={question.props.children[0]} answer={answer} />;
};

const InLink = (props: any) => {
  return (
    <BlogPostInlink slug={props.slug} title={props.title} hook={props.hook} />
  );
};

const SocialShareBox = (props: any, url: string) => {
  return (
    <>
      <TwitterShareButton
        target={"_blank"}
        href={`https://twitter.com/intent/tweet?url=${url}`}
        blackWhite={false}
      >
        <Icon type={"twitter"} size={16} fill={theme.shareIconsColor} />
      </TwitterShareButton>
      <FacebookShareButton
        target={"_blank"}
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        blackWhite={false}
      >
        <Icon type={"facebook"} size={16} fill={theme.shareIconsColor} />
      </FacebookShareButton>
      <LinkedinShareButton
        target={"_blank"}
        href={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        blackWhite={false}
      >
        <Icon type={"linkedin"} size={16} fill={theme.shareIconsColor} />
      </LinkedinShareButton>
    </>
  );
};

const SignupBox = (props: any) => {
  const title = getObjectByType(props, "h3");
  const description = getObjectByType(props, "span");

  return (
    <RegisterForBlog
      title={title.props.children ? title.props.children[0] : null}
      description={
        description.props.children ? description.props.children[0] : null
      }
    />
  );
};

const CannedResponseTemplatePreview = (
  props: any,
  templates: CannedResponseTemplate[]
) => {
  const template = templates.find(
    (template) => template.canned_response_template_id === Number(props.id)
  );
  return template ? <CannedResponsePreview cannedResponse={template} /> : <></>;
};

const SurveyTemplatePreview = (props: any, templates: SurveyTemplate[]) => {
  const template = templates.find(
    (template) => template.survey_template_id === Number(props.id)
  );
  return template ? <SurveyPreview survey={template} /> : <></>;
};

const EmailTemplatePreview = (props: any, templates: EmailTemplate[]) => {
  const template = templates.find(
    (template) => template.community_email_template_id === Number(props.id)
  );
  return template ? <EmailPreview email={template} /> : <></>;
};

const ChatTemplatePreview = (props: any, templates: ChatTemplate[]) => {
  const template = templates.find(
    (template) => template.trigger_template_id === Number(props.id)
  );
  return template ? <ChatPreview chat={template} /> : <></>;
};

const NewsletterSubscribe = (utmCampaign: string) => {
  return <BlogSubscribe condensed utmCampaign={utmCampaign} />;
};

const getTextContentFromElement = (
  elem: React.ReactElement | string
): string => {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }

  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getTextContentFromElement).join("");
  }
  return getTextContentFromElement(children);
};

const H2ToBlogTitle = (props: any, location: Location) => {
  const id = slugify(getTextContentFromElement(props.children[0]));

  return <BlogTitle {...props} location={location} id={id} />;
};

export {
  getTextInTitle,
  LinkFixer,
  LightBoxPictureInFigure,
  LightBoxPictureInDiv,
  NewsletterSubscribe,
  H2ToBlogTitle,
  SurveyTemplatePreview,
  CannedResponseTemplatePreview,
  EmailTemplatePreview,
  ChatTemplatePreview,
  FAQElement,
  InLink,
  SocialShareBox,
  SignupBox,
};
