import styled from "styled-components";
import { StyledRegisterCTA } from "components/ui/extended/RegisterCTA/index.style";

const StyledRegisterForBlog = styled.div`
  margin: 16px 0 32px;

  ${StyledRegisterCTA} {
    width: fit-content;

    a {
      color: ${(props) => props.theme.whiteColor};
    }
  }
`;

export { StyledRegisterForBlog };
