import React from "react";
import { ChatTemplate } from "app-types/template";
import Heading from "components/core/Typography/Heading";
import SafeLink from "components/core/SafeLink";
import { getChatTemplatePagePath } from "utils/path";
import { useTranslation } from "react-i18next";
import Fade from "components/core/Animation/extended/Fade";
import MessengerPreview from "components/ui/extended/MessengerPreview";
import Notification from "components/ui/extended/MessengerPreview/Notification";
import {
  StyledTemplatePreview,
  TemplateBackground,
  TemplateDescription,
  TemplateFooter,
  TemplateFooterLeft,
  TemplateFooterRight,
} from "../index.style";
import Button from "components/core/Button";
import { theme } from "styles/theme";
import { COMMUNITY_AUTHOR } from "constants/community";

interface ChatPreviewProps {
  chat: ChatTemplate;
}

const ChatPreview = (props: ChatPreviewProps) => {
  const { t } = useTranslation();

  return (
    <StyledTemplatePreview>
      <TemplateBackground show>
        <Fade direction={"up"}>
          <MessengerPreview
            withBubble
            direction={"left"}
            accentColor={theme.accentColor}
          >
            <Notification
              name={COMMUNITY_AUTHOR.name}
              accentColor={theme.accentColor}
              accountId={COMMUNITY_AUTHOR.id}
              messages={[
                props.chat.chat.actions[0].contents[0].content.content || "",
              ]}
            />
          </MessengerPreview>
        </Fade>
      </TemplateBackground>

      <TemplateFooter>
        <TemplateFooterLeft>
          <Heading level={4}>{props.chat.name}</Heading>
          <TemplateDescription>{props.chat.description}</TemplateDescription>
        </TemplateFooterLeft>
        <TemplateFooterRight>
          <Button
            as={SafeLink}
            to={getChatTemplatePagePath(
              props.chat.trigger_template_id,
              props.chat.name,
              props.chat.author.username
            )}
            icon={{ type: "arrow-right", size: 16 }}
          >
            {t("components.ui.base.TemplatePreview.goTo")}
          </Button>
        </TemplateFooterRight>
      </TemplateFooter>
    </StyledTemplatePreview>
  );
};

export default ChatPreview;
