import React from "react";
import { EmailTemplate } from "app-types/template";
import SafeLink from "components/core/SafeLink";
import Heading from "components/core/Typography/Heading";
import { useTranslation } from "react-i18next";
import {
  StyledTemplatePreview,
  TemplateDescription,
  TemplateFooter,
  TemplateFooterLeft,
  TemplateFooterRight,
} from "components/ui/base/TemplatePreview/index.style";
import Button from "components/core/Button";
import { getEmailTemplatePagePath } from "utils/path";

interface EmailPreviewProps {
  email: EmailTemplate;
}

const EmailPreview = (props: EmailPreviewProps) => {
  const { t } = useTranslation();

  return (
    <StyledTemplatePreview>
      <img
        src={props.email.thumbnail_url}
        alt={`email-template-preview-${props.email.name}`}
      />

      <TemplateFooter>
        <TemplateFooterLeft>
          <Heading level={4}>{props.email.name}</Heading>
          <TemplateDescription>{props.email.description}</TemplateDescription>
        </TemplateFooterLeft>

        <TemplateFooterRight>
          <Button
            as={SafeLink}
            to={getEmailTemplatePagePath(
              props.email.community_email_template_id,
              props.email.name,
              props.email.author.username
            )}
            icon={{ type: "arrow-right", size: 16 }}
          >
            {t("components.ui.base.TemplatePreview.goTo")}
          </Button>
        </TemplateFooterRight>
      </TemplateFooter>
    </StyledTemplatePreview>
  );
};

export default EmailPreview;
