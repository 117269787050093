import React from "react";
import { CannedResponseTemplate } from "app-types/template";
import { MessageContainer, MessageContentInnerContainer } from "./index.style";
import { useTranslation } from "react-i18next";
import Avatar from "components/ui/base/Avatar";
import SafeLink from "components/core/SafeLink";
import { getCannedResponseTemplatePagePath } from "utils/path";
import Heading from "components/core/Typography/Heading";
import {
  StyledTemplatePreview,
  TemplateBackground,
  TemplateDescription,
  TemplateFooter,
  TemplateFooterLeft,
  TemplateFooterRight,
} from "../index.style";
import Button from "components/core/Button";

interface CannedResponsePreviewProps {
  cannedResponse: CannedResponseTemplate;
}

const CannedResponsePreview = (props: CannedResponsePreviewProps) => {
  const { t } = useTranslation();

  return (
    <StyledTemplatePreview>
      <TemplateBackground show>
        <MessageContainer>
          <MessageContentInnerContainer
            dangerouslySetInnerHTML={{
              __html: props.cannedResponse.canned_response.text,
            }}
          />
          <Avatar size={"medium"} accountId={3} />
        </MessageContainer>
      </TemplateBackground>
      <TemplateFooter>
        <TemplateFooterLeft>
          <Heading level={4}>
            {props.cannedResponse.canned_response.title}
          </Heading>
          <TemplateDescription>
            {props.cannedResponse.description}
          </TemplateDescription>
        </TemplateFooterLeft>

        <TemplateFooterRight>
          <Button
            as={SafeLink}
            to={getCannedResponseTemplatePagePath(
              props.cannedResponse.canned_response_template_id,
              props.cannedResponse.canned_response.title,
              props.cannedResponse.author.username
            )}
            icon={{ type: "arrow-right", size: 16 }}
          >
            {t("components.ui.base.TemplatePreview.goTo")}
          </Button>
        </TemplateFooterRight>
      </TemplateFooter>
    </StyledTemplatePreview>
  );
};

export default CannedResponsePreview;
