import styled, { css } from "styled-components";
import { StyledButton } from "components/core/Button/index.style";

interface TemplateBackgroundProps {
  show: boolean;
}

const StyledTemplatePreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 48px 0;
  border-radius: ${(props) => props.theme.borderRadius};
  background: ${(props) => props.theme.baseLighten95Color};
  padding: 16px;
`;

const TemplateFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  gap: 24px;

  h4 {
    margin: 0 !important;
    font-weight: 600;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const TemplateFooterLeft = styled.div``;

const TemplateFooterRight = styled.div`
  ${StyledButton}, a {
    font-size: 16px;
    color: ${(props) => props.theme.whiteColor} !important;
    width: max-content;
  }
`;

const TemplateDescription = styled.p`
  color: ${(props) => props.theme.baseLightColorTransparent} !important;
  margin: 0 !important;
`;

const TemplateBackground = styled.div<TemplateBackgroundProps>`
  min-height: 800px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 64px 0;

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export {
  StyledTemplatePreview,
  TemplateBackground,
  TemplateFooter,
  TemplateFooterLeft,
  TemplateFooterRight,
  TemplateDescription,
};
