import React from "react";
import { StyledInlink } from "./index.style";

export interface BlogPostInlinkProps {
  hook: string;
  slug: string;
  title: string;
}

const BlogPostInlink = (props: BlogPostInlinkProps) => {
  return (
    <StyledInlink>
      <h5>{props.hook}</h5>
      <a href={`/blog${props.slug}`}>{props.title}</a>
    </StyledInlink>
  );
};

export default BlogPostInlink;
