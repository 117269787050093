import React from "react";
import { StyledRegisterForBlog } from "components/ui/extended/RegisterForBlog/index.style";
import { useTranslation } from "react-i18next";
import Heading from "components/core/Typography/Heading";
import RegisterCTA from "components/ui/extended/RegisterCTA";

export interface RegisterForBlogProps {
  title?: string;
  description?: string;
}

export default ({ title, description }: RegisterForBlogProps) => {
  const { t } = useTranslation();

  return (
    <StyledRegisterForBlog>
      {title && <Heading level={4}>{title}</Heading>}
      {description && <p>{description}</p>}
      <RegisterCTA />
    </StyledRegisterForBlog>
  );
};
