import styled from "styled-components";

const StyledInlink = styled.div`
  padding: 32px;
  border-radius: 16px;
  background: linear-gradient(212deg, #bfe9fe, #2fb6fa33);

  h5 {
    margin-bottom: 8px;
  }

  a {
    text-decoration: underline;
  }
`;

export { StyledInlink };
